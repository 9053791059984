<template>
  <view-wrapper>
    <a-form>
      <div class="flex justify-between">
        <div class="card">
          <div class="flex justify-between card-top-line">
            <span>成交金额</span>
            <span>{{ form.data.date }}</span>
          </div>
          <div class="flex justify-center card-middle-line">
            <span>{{ formatMoney(form.data.purchaseAmount, '￥') }}</span>
          </div>
          <div class="flex justify-center card-bottom-line">
            <span style="color: rgba(0, 0, 0, 0.85)">
              上月成交:{{ formatMoney(form.data.purchaseAmountLastMonth, '￥') }}
            </span>
            <span style="color: rgba(255, 77, 0, 1); margin-left: 50px">
              <template v-if="form.data.purchaseAmountRise === 'Y'">
                <arrow-up-outlined />
                <span> {{ form.data.purchaseAmountRiseRate }} </span>
              </template>
              <template v-else>
                <arrow-down-outlined />
                <span style="margin-left: 4px">
                  {{ form.data.purchaseAmountFallRate }}
                </span>
              </template>
            </span>
          </div>
        </div>
        <div class="card" style="margin: 0 24px">
          <div class="flex justify-between card-top-line">
            <span>收到询价单数 </span>
            <span>{{ form.data.date }}</span>
          </div>
          <div class="flex justify-center card-middle-line">
            <span>{{ form.data.inquiryCount }}</span>
            <span class="card-middle-line__text">单</span>
          </div>
          <div class="flex justify-center card-bottom-line">
            <span style="color: rgba(0, 0, 0, 0.85)"> 上月收到:{{ form.data.inquiryCountLastMonth }} </span>
            <span style="color: rgba(255, 77, 0, 1); margin-left: 50px">
              <template v-if="form.data.inquiryCountRise === 'Y'">
                <arrow-up-outlined />
                <span> {{ form.data.inquiryCountRiseRate }} </span>
              </template>
              <template v-else>
                <arrow-down-outlined style="margin-right: 4px" />
                <span> {{ form.data.inquiryCountFallRate }} </span>
              </template>
            </span>
          </div>
        </div>
        <div class="card">
          <div class="flex justify-between card-top-line">
            <span>成交单数</span>
            <span>{{ form.data.date }}</span>
          </div>
          <div class="flex justify-center card-middle-line">
            <span>{{ form.data.purchaseCount }}</span>
            <span class="card-middle-line__text">单</span>
          </div>
          <div class="flex justify-center card-bottom-line">
            <span style="color: rgba(0, 0, 0, 0.85)"> 上月成交:{{ form.data.purchaseCountLastMonth }} </span>
            <span style="color: rgba(255, 77, 0, 1); margin-left: 50px">
              <template v-if="form.data.purchaseCountRise === 'Y'">
                <arrow-up-outlined />
                <span> {{ form.data.purchaseCountRiseRate }} </span>
              </template>
              <template v-else>
                <arrow-down-outlined style="margin-right: 4px" />
                <span> {{ form.data.purchaseCountFallRate }} </span>
              </template>
            </span>
          </div>
        </div>
      </div>
      <div class="flex" style="margin-top: 24px">
        <div class="content-left">
          <div class="flex justify-between card-top-line" style="margin-top: 16px; margin-bottom: 24px">
            <span class="font-bold">配件成交量详情 </span>
            <span>{{ form.data.date }}</span>
          </div>
          <a-table
            :pagination="false"
            :data-source="form.data.goodsSummaries"
            :columns="form.tableColumns"
            row-key="goodsName"
          >
            <template #index="{ index }">
              <span>{{ index + 1 }}</span>
            </template>
            <template #count="{ text }">
              <span>{{ text }}</span>
            </template>
            <template #amount="{ text }">
              <span>{{ formatMoney(text, '￥') }}</span>
            </template>
          </a-table>
        </div>
        <div class="content-right flex flex-col">
          <div class="top-part">
            <div class="flex justify-between top-part__header">
              <span style="font-size: 16px; color: rgba(0, 0, 0, 0.85)">我的粉丝</span>
              <span style="color: rgba(0, 0, 0, 0.45)">{{ form.data.date }}</span>
            </div>
            <div class="flex justify-evenly items-center top-part__main">
              <div>
                <div style="color: rgba(0, 0, 0, 0.45)">粉丝数量</div>
                <div style="font-size: 30px">{{ form.data.branchCount }}</div>
              </div>
              <a-divider type="vertical" style="background: #e9e9e9; height: 40px" />
              <div>
                <div style="color: rgba(0, 0, 0, 0.45)">粉丝成交单数</div>
                <div style="font-size: 30px">
                  {{ form.data.branchPurchaseCount }}
                </div>
              </div>
              <a-divider type="vertical" style="background: #e9e9e9; height: 40px" />
              <div>
                <div style="color: rgba(0, 0, 0, 0.45)">粉丝成交金额（元）</div>
                <div style="font-size: 30px">
                  {{ form.data.branchPurchaseAmount }}
                </div>
              </div>
            </div>
          </div>

          <div class="mt-[20px]" v-if="form.data.vehicleSummaries.length">
            <a-card :loading="summaryLoading">
              <template #title>
                <div class="flex justify-between text-[14px] text-gray-400">
                  <span>车品牌成交top5</span>
                  <span>{{ form.data.date }}</span>
                </div>
              </template>
              <e-echarts
                autoresize
                :option="{
                  tooltip: { trigger: 'item' },
                  legend: {
                    type: 'scroll',
                    orient: 'vertical',
                    top: 'middle',
                    right: 20,
                    formatter: echartsLegendFormatter,
                    textStyle: {
                      rich: {
                        x: { color: 'rgba(0, 0, 0, 0.45)' }
                      }
                    }
                  },
                  dataset: { dimensions: ['vehicleBrand', 'purchaseCount'], source: form.data.vehicleSummaries },
                  series: [
                    {
                      name: '车品牌成交',
                      right: 200,
                      type: 'pie',
                      radius: ['40%', '70%'],
                      avoidLabelOverlap: false,
                      itemStyle: { borderRadius: 10, borderColor: '#fff', borderWidth: 2 },
                      label: { show: false, position: 'center' },
                      emphasis: {
                        label: {
                          show: true,
                          fontSize: '40',
                          fontWeight: 'bold'
                        }
                      },
                      labelLine: { show: false }
                    }
                  ]
                }"
              ></e-echarts>
            </a-card>
          </div>
        </div>
      </div>
    </a-form>
  </view-wrapper>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'
import { multiply } from 'lodash-es'
import { useAjax, formatMoney, formatDate } from '@vue-mfe/utils'
import type { ColumnProps } from 'ant-design-vue/es/table/interface'
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons-vue'
import { Table as ATalbe, Divider as ADivider, Card as ACard } from 'ant-design-vue'

const columns = [
  { title: '序号', width: 80, customRender: ({ index }) => index + 1 },
  { title: '配件名称', with: 200, ellipsis: true, dataIndex: 'goodsName' },
  { title: '成交量', dataIndex: 'purchaseCount' },
  { title: '成交金额', dataIndex: 'purchaseAmount', customRender: ({ text }) => formatMoney(text, '￥') }
] as ColumnProps[]
const toPercent = (value: number, precision = 2) => {
  return `${multiply(value, 100).toFixed(precision)}%`
}
export default defineComponent(
  {
    components: {
      ATalbe,
      ADivider,
      ArrowUpOutlined,
      ArrowDownOutlined,
      ACard
    },
    setup () {
      const { loading: summaryLoading, run: summaryRun } = useAjax(
        {
          action: 'GET /supplier/summary',
          lazy: true
        }
      )
      const form = reactive(
        {
          get: () => {
            summaryRun().then(
              (res) => {
                const data = res.data.data[0]
                let {
                  date,
                  inquiryCount,
                  inquiryCountLastMonth,
                  purchaseAmount,
                  purchaseAmountLastMonth,
                  purchaseCount,
                  purchaseCountLastMonth
                } = data
                // 询价订单部分
                if (inquiryCountLastMonth === 0) {
                  data.inquiryCountRise = 'Y'
                  data.inquiryCountRiseRate = `比上月增长了${inquiryCount - inquiryCountLastMonth}单`
                } else if (inquiryCount - inquiryCountLastMonth >= 0) {
                  data.inquiryCountRise = 'Y'
                  data.inquiryCountRiseRate = toPercent((inquiryCount - inquiryCountLastMonth) / inquiryCountLastMonth)
                } else {
                  data.inquiryCountRise = 'N'
                  data.inquiryCountFallRate = toPercent(
                    Math.abs(inquiryCount - inquiryCountLastMonth) / inquiryCountLastMonth
                  )
                }
                // 成交金额部分
                if (purchaseAmountLastMonth === 0) {
                  data.purchaseAmountRise = 'Y'
                  data.purchaseAmountRiseRate = `比上月增长了${purchaseAmount - purchaseAmountLastMonth}￥`
                } else if (purchaseAmount - purchaseAmountLastMonth >= 0) {
                  data.purchaseAmountRise = 'Y'
                  data.purchaseAmountRiseRate = toPercent(
                    (purchaseAmount - purchaseAmountLastMonth) / purchaseAmountLastMonth
                  )
                } else {
                  data.purchaseAmountRise = 'N'
                  data.purchaseAmountFallRate = toPercent(
                    Math.abs(purchaseAmount - purchaseAmountLastMonth) / purchaseAmountLastMonth
                  )
                }
                // 成交单数部分
                if (purchaseCountLastMonth === 0) {
                  data.purchaseCountRise = 'Y'
                  data.purchaseCountRiseRate = `比上月增长了${purchaseCount - purchaseCountLastMonth}单`
                } else if (purchaseCount - purchaseCountLastMonth >= 0) {
                  data.purchaseCountRise = 'Y'
                  data.purchaseCountRiseRate = toPercent(
                    (purchaseCount - purchaseCountLastMonth) / purchaseCountLastMonth
                  )
                } else {
                  data.purchaseCountRise = 'N'
                  data.purchaseCountFallRate = toPercent(
                    Math.abs(purchaseCount - purchaseCountLastMonth) / purchaseCountLastMonth
                  )
                }
                data.date = formatDate(date, 'YYYY年MM月')
                form.data = Object.assign(form.data, data)
              }
            )
          },
          data: {
            date: '',
            branchCount: 0,
            branchPurchaseAmount: 0,
            branchPurchaseCount: 0,
            goodsSummaries: [] as any[],
            inquiryCount: 0,
            inquiryCountLastMonth: 0,
            inquiryQuoteCount: 0,
            inquiryCountRise: '',
            inquiryCountRiseRate: 0,
            inquiryCountFallRate: 0,
            inquiryQuoteCountLastMonth: 0,
            purchaseAmount: 0,
            purchaseAmountLastMonth: 0,
            purchaseAmountRise: '',
            purchaseAmountRiseRate: 0,
            purchaseAmountFallRate: 0,
            purchaseCount: 0,
            purchaseCountLastMonth: 0,
            purchaseCountRise: '',
            purchaseCountRiseRate: 0,
            purchaseCountFallRate: 0,
            supplierCount: 0,
            supplierPurchaseAmount: 0,
            supplierPurchaseCount: 0,
            supplierSummaries: [] as any[],
            vehicleSummaries: [] as any[]
          },
          tableColumns: columns
        }
      )
      form.get()

      const echartsLegendFormatter = (name: string) => {
        const current = form.data.vehicleSummaries.find((item) => item.vehicleBrand === name)
        const sum = form.data.vehicleSummaries.reduce((sum, item) => (sum += item.purchaseCount), 0)
        return `${current!.vehicleBrand}  {x| | ${toPercent(current!.purchaseCount / sum)}} ${formatMoney(
          current!.purchaseAmount,
          '￥'
        )}`
      }

      return {
        form,
        formatMoney,
        summaryLoading,
        echartsLegendFormatter
      }
    }
  }
)
</script>

<style scoped>
.card {
  flex: 1;
  min-width: 348px;
  height: 152px;
  box-sizing: border-box;
  padding: 16px;
  background: #fff;
}
.card-top-line {
  color: rgba(0, 0, 0, 0.45);
}
.card-middle-line {
  margin: 20px 0 10px 0;
  color: rgba(13, 141, 141, 1);
  font-size: 36px;
  align-items: center;
}
.card-middle-line__text {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  margin-left: 10px;
}
.card-bottom-line {
  color: rgba(0, 0, 0, 0.45);
}
.content-left {
  flex: 1;
  min-width: 580px;
  background: #fff;
  padding: 0 24px;
}
.content-right {
  width: 580px;
  min-width: 580px;
  margin-left: 24px;
}
.top-part {
  background: #fff;
}
.top-part__header {
  padding: 16px 24px;
  border-bottom: 1px solid #e9e9e9;
}
.top-part__main {
  height: 118px;
  text-align: center;
}
</style>
